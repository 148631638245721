import React from 'react';

type HeadingProps = {
    children: React.ReactNode;
    className?: string;
    size?: 'text-3xl' | 'text-4xl' | 'text-5xl';
    align?: 'text-left' | 'text-center' | 'text-right';
};

const Heading: React.FC<HeadingProps> = ({
                                             children,
                                             className = '',
                                             size = 'text-3xl',
                                             align = 'text-center'
                                         }) => {
    return (
        <h2
            className={`${size} md:${size} font-bold text-gray-800 mb-8 ${align} ${className}`}
            data-aos="fade-up"
        >
            {children}
        </h2>
    );
};

export default Heading;
