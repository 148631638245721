import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { TransparentLogo } from '../assets/images';
import { FiMenu, FiX } from 'react-icons/fi';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const headerHeight = 64;

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
      <nav className="fixed top-0 w-full bg-white flex items-center justify-start p-4 shadow-md z-20">
        <Link
            to="hero"
            smooth={true}
            duration={700}
            offset={-headerHeight}
            className="cursor-pointer hover:text-blue-500"
        >
          <div className="flex items-center ml-6">
            <img src={TransparentLogo} alt="Logo" className="h-12 mr-2" />
            <span className="text-xl font-bold text-gray-800">Cirrus Bridge</span>
          </div>
        </Link>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex space-x-8 text-gray-800 ml-10"> {/* Adjust the `ml-10` to fine-tune spacing */}
          <li>
            <Link
                to="services"
                smooth={true}
                duration={700}
                offset={-headerHeight}
                className="cursor-pointer hover:text-blue-500"
            >
              What We Offer
            </Link>
          </li>
          <li>
            <Link
                to="clients"
                smooth={true}
                duration={700}
                offset={-headerHeight}
                className="cursor-pointer hover:text-blue-500"
            >
              Trusted by Leading Brands
            </Link>
          </li>
          <li>
            <Link
                to="values"
                smooth={true}
                duration={700}
                offset={-headerHeight}
                className="cursor-pointer hover:text-blue-500"
            >
              What Drives Us
            </Link>
          </li>
          <li>
            <Link
                to="contact"
                smooth={true}
                duration={700}
                offset={-headerHeight}
                className="cursor-pointer hover:text-blue-500"
            >
              Let’s Connect
            </Link>
          </li>
        </ul>

        {/* Mobile Menu Icon */}
        <div className="md:hidden flex items-center ml-auto">
          <button onClick={toggleMobileMenu} className="focus:outline-none text-gray-800">
            {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
            <div className="absolute top-16 left-0 w-full bg-white shadow-lg flex flex-col items-center md:hidden">
              <Link
                  to="services"
                  smooth={true}
                  duration={700}
                  offset={-headerHeight}
                  className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                  onClick={toggleMobileMenu}
              >
                What We Offer
              </Link>
              <Link
                  to="clients"
                  smooth={true}
                  duration={700}
                  offset={-headerHeight}
                  className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                  onClick={toggleMobileMenu}
              >
                Trusted by Leading Brands
              </Link>
              <Link
                  to="values"
                  smooth={true}
                  duration={700}
                  offset={-headerHeight}
                  className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                  onClick={toggleMobileMenu}
              >
                What Drives Us
              </Link>
              <Link
                  to="contact"
                  smooth={true}
                  duration={700}
                  offset={-headerHeight}
                  className="cursor-pointer py-4 text-gray-800 hover:text-blue-500 w-full text-center"
                  onClick={toggleMobileMenu}
              >
                Let’s Connect
              </Link>
            </div>
        )}
      </nav>
  );
};

export default Navbar;
