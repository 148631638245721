import React, { useEffect, useState } from 'react';
import './SpinningElement.css';
import { TransparentLogo } from '../assets/images';
import { motion, useAnimation } from 'framer-motion';

const SpinningElement: React.FC = () => {
    const [isInContactView, setIsInContactView] = useState(false);
    const controls = useAnimation();

    useEffect(() => {
        const contactSection = document.getElementById('contact');

        if (contactSection) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach(entry => {
                        setIsInContactView(entry.isIntersecting);
                    });
                },
                { threshold: 0.1 } // Adjust as needed for sensitivity
            );

            observer.observe(contactSection);
            return () => {
                observer.disconnect();
            };
        }
    }, []);

    const scrollToContact = () => {
        controls.start({ opacity: 0, transition: { duration: 0.8, ease: 'easeInOut' } });
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            const yOffset = -64; // Adjust this value for the desired offset
            const yPosition = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({
                top: yPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isInContactView ? 0 : 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="spinning-element-wrapper"
            onClick={scrollToContact}
        >
            <div className="spinning-container">
                {/* Static logo in the center */}
                <div className="logo-container pulsate">
                    <img src={TransparentLogo} alt="Logo" className="logo-image" />
                </div>

                <svg
                    className="circular-text"
                    width="200"
                    height="200"
                    viewBox="0 0 200 200"
                >
                    <defs>
                        <path
                            id="circlePath"
                            d="
                                M 100, 100
                                m -80, 0
                                a 79,79 0 1,1 160,0
                                a 79,79 0 1,1 -160,0
                            "
                        />
                    </defs>
                    <text
                        fill="#002C6E"
                        fontSize="14"
                        fontWeight="bold"
                        textLength="502.65"
                        lengthAdjust="spacingAndGlyphs"
                    >
                        <textPath href="#circlePath" startOffset="0">
                            • Let's Talk Software Development •• Let's Talk Cloud Solutions •
                        </textPath>
                    </text>
                </svg>
            </div>
        </motion.div>
    );
};

export default SpinningElement;
