import React from 'react';
import { Info } from '../assets/images';
import { Typewriter } from 'react-simple-typewriter';
import { Link } from 'react-scroll';
import GoogleReviewsButton from "./google-reviews";

const HeroSection: React.FC = () => {
    const headerHeight = 64;

    return (
        <section id="hero" className="relative flex justify-center items-center min-h-screen pt-28 md:pt-0">
            <div className="flex flex-col md:flex-row items-center max-w-7xl mx-auto px-6 md:px-12">
                <div className="md:w-1/2 mr-0 md:mr-12 text-center md:text-left">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-800 leading-tight">
                        Building tomorrow's software <br /> and cloud solutions with <br />
                        <span style={{ color: '#002C6E' }}>
                <Typewriter
                    words={['Collaborative Innovation', 'Cutting-edge Technology', 'Trust and Transparency']}
                    loop={false}
                    cursor
                    cursorStyle="_"
                    typeSpeed={80}
                    deleteSpeed={50}
                    delaySpeed={2000}
                />
              </span>
                    </h1>
                    <p className="text-lg md:text-xl text-gray-600 mt-4">
                        At Cirrus Bridge, we craft custom software solutions that evolve with your business. From innovative concepts to seamless deployment, our expert team delivers scalable, high-performance software that enhances efficiency and keeps you ahead in the competitive landscape.
                    </p>
                </div>
                <div className="md:w-3/5 flex justify-center items-center mt-8 md:mt-0">
                    <img src={Info} alt="Info" className="w-full h-auto object-cover rounded-lg" />
                </div>
            </div>

            {/* SVG scroll indicator positioned at the bottom of the section */}
            <Link offset={-headerHeight} to="services" smooth={true} duration={500} className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer hidden md:block z-2">
                <svg className="w-10 h-10 text-gray-800 hover:text-blue-500 animate-bounce" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7l5 5 5-5M7 13l5 5 5-5"></path>
                </svg>
            </Link>

            {/* Google Reviews Button positioned at the bottom left */}
            <GoogleReviewsButton />

        </section>
    );
};

export default HeroSection;
