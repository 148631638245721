import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Idea, Target, Trusted } from '../assets/images';
import Heading from "./heading";

const CoreValuesSection: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
      <section id="values" className="py-16 bg-transparent">
        <div className="max-w-7xl mx-auto px-6 md:px-12 text-center">
          <Heading>What Drives Us</Heading>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="flex flex-col items-center" data-aos="fade-right">
              <img src={Idea} alt="Innovation" className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-bold text-gray-800">Innovation</h3>
              <p className="text-gray-600 mt-2">
                We believe in pushing the boundaries of technology, continuously innovating to deliver cutting-edge solutions to our clients.
              </p>
            </div>
            <div className="flex flex-col items-center" data-aos="zoom-in" data-aos-delay="200">
              <img src={Trusted} alt="Trust" className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-bold text-gray-800">Trust</h3>
              <p className="text-gray-600 mt-2">
                Building long-lasting relationships through honesty and transparency, we earn the trust of our clients by delivering on our promises.
              </p>
            </div>
            <div className="flex flex-col items-center" data-aos="fade-left" data-aos-delay="400">
              <img src={Target} alt="Future-Focused" className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-bold text-gray-800">Future-Focused</h3>
              <p className="text-gray-600 mt-2">
                We stay ahead of industry trends, ensuring our clients are always prepared for the opportunities and challenges that lie ahead.
              </p>
            </div>
          </div>
        </div>
      </section>
  );
};

export default CoreValuesSection;
