import React, { useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    CircularProgress,
    Box,
    Avatar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface Review {
    author_name: string;
    rating: number;
    text: string;
    profile_photo_url: string;
    author_url: string;
}

interface GoogleReviewsModalProps {
    open: boolean;
    onClose: () => void;
}

const fetchReviews = async (): Promise<Review[]> => {
    const url = `https://get-google-reviews-website.azurewebsites.net/api/get-google-reviews?code=Go4AoWjWtxcqfQ86XyH94jGUSKTU0OP6oYbqC1uSQ0M3AzFuKZ1leg%3D%3D`;
    const response = await axios.get(url);
    return response.data;
};

function GoogleReviewsModal({ open, onClose }: GoogleReviewsModalProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { data: reviews = [], isLoading, isError, refetch } = useQuery({
        queryKey: ['googleReviews'],
        queryFn: fetchReviews,
        enabled: open,
        staleTime: 5 * 60 * 1000,
    });

    useEffect(() => {
        if (open) {
            document.documentElement.style.overflow = 'hidden';
            refetch();
            console.log('Modal opened, overflow set to hidden.');
        }

        return () => {
            console.log('Modal closed, resetting overflow.');
            document.documentElement.style.overflow = ''; // Reset on `<html>` instead of `<body>`
        };
    }, [open, refetch]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile} // Full-screen modal on mobile
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                Our Reviews
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                    </Box>
                ) : isError ? (
                    <Typography align="center">Error fetching reviews.</Typography>
                ) : reviews.length > 0 ? (
                    reviews.map((review: Review, index: number) => (
                        <Box key={index} sx={{ display: 'flex', mb: 3, padding: '10px 0', borderBottom: '1px solid #e0e0e0' }}>
                            <Avatar
                                src={review.profile_photo_url}
                                alt={review.author_name}
                                sx={{ width: 50, height: 50, mr: 2 }}
                            />
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    <a
                                        href={review.author_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {review.author_name}
                                    </a>
                                </Typography>
                                <Typography variant="subtitle2" sx={{ color: '#666', mb: 0.5 }}>
                                    Rating: {review.rating} ⭐
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1 }}>
                                    {review.text}
                                </Typography>
                            </Box>
                        </Box>
                    ))
                ) : (
                    <Typography align="center">No reviews available.</Typography>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default GoogleReviewsModal;

