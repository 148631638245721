import React, { useState } from 'react';
import { Fab, Typography, Box, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import GoogleReviewsModal from "./google-reviews-modal";
import { useTheme } from '@mui/material/styles';

function GoogleReviewsButton() {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1,
                }}
            >
                <Fab
                    sx={{
                        backgroundColor: '#4285F4', // Google Blue
                        color: '#fff',
                        '&:hover': {
                            backgroundColor: '#357AE8', // Darker Google Blue on hover
                        },
                        mr: 1,
                    }}
                    aria-label="Google Reviews"
                    onClick={() => setOpen(true)}
                >
                    <StarIcon />
                </Fab>

                {!isMobile && (
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 'bold', color: '#4285F4', cursor: 'pointer' }}
                        onClick={() => setOpen(true)}
                    >
                        Google Reviews
                    </Typography>
                )}
            </Box>
            <GoogleReviewsModal open={open} onClose={() => setOpen(false)} />
        </>
    );
}

export default GoogleReviewsButton;
