import React from "react";
import ServicesSection from "../Components/our-services";
import CoreValuesSection from "../Components/core-values";
import ContactInformation from "../Components/contact-us";
import HeroSection from "../Components/hero-section";
import Navbar from "../Components/Navbar";
import ClientsSection from "../Components/our-clients";
import SpinningElement from "../Components/spinner";
import GoogleReviewsBadge from "../Components/google-reviews";
import WorldGlobe from "../Components/world-map";
import SoftwareDevelopmentFlow from "../Components/our-process";

function Home() {
    return (
        <div className="background" style={{
            overflowX: 'hidden',
            position: 'relative',
        }}>
            <Navbar/>
            <HeroSection/>
            <ServicesSection/>
            {/*<SoftwareDevelopmentFlow></SoftwareDevelopmentFlow>*/}
            <ClientsSection/>
            <WorldGlobe></WorldGlobe>
            <CoreValuesSection/>
            <ContactInformation/>
        </div>//unpkg.com/three-globe/example/img/earth-topology.png
    );
}

export default Home;
