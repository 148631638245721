import { json } from 'd3-fetch';
import { geoOrthographic, geoPath } from 'd3-geo';
import { feature, mesh } from 'topojson-client';
import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Heading from "./heading";

interface WorldData {
    land: any;
    borders: any;
}

interface Location {
    name: string;
    coordinates: [number, number];
    projects?: number;
}

const WorldGlobe = () => {
    const [data, setData] = useState<WorldData | null>(null);
    const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);
    const [tooltipPos, setTooltipPos] = useState<{ x: number; y: number } | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const lastMousePosition = useRef({ x: 0, y: 0 });
    const rotation = useRef<[number, number]>([-5, 0]);

    const locations: Location[] = [
        { name: 'Nelspruit', coordinates: [30.9703, -25.4745], projects: 5 },
        { name: 'Gauteng', coordinates: [28.0473, -26.2041], projects: 8 },
        { name: 'London', coordinates: [-0.1278, 51.5074], projects: 12 }
    ];

    useEffect(() => {
        json('https://unpkg.com/world-atlas@2.0.2/countries-110m.json').then((topoData: any) => {
            const { countries, land } = topoData.objects;
            setData({
                land: feature(topoData, land),
                borders: mesh(topoData, countries, (a: any, b: any) => a !== b)
            });
        });
    }, []);

    const render = () => {
        if (!data || !canvasRef.current) return;

        const context = canvasRef.current.getContext('2d');
        if (!context) return;

        const dpr = window.devicePixelRatio || 1;
        canvasRef.current.width = 800 * dpr;
        canvasRef.current.height = 600 * dpr;
        context.scale(dpr, dpr);
        context.clearRect(0, 0, 800, 600);
        context.imageSmoothingEnabled = true;

        const projection = geoOrthographic()
            .scale(250)
            .translate([400, 250])
            .rotate(rotation.current);

        const pathGenerator = geoPath(projection, context);

        const createLandGradient = () => {
            const gradient = context.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, '#0088D4');
            gradient.addColorStop(1, '#002C6E');
            return gradient;
        };
        const landGradient = createLandGradient();

        // Draw the globe stand
        context.fillStyle = '#555';
        context.beginPath();
        context.ellipse(400, 500, 150, 30, 0, 0, 2 * Math.PI);
        context.fill();

        context.fillStyle = '#333';
        context.beginPath();
        context.ellipse(400, 470, 80, 10, 0, 0, 2 * Math.PI);
        context.fill();

        // Draw the globe
        context.beginPath();
        pathGenerator({ type: 'Sphere' });
        context.fillStyle = '#e0f7ff';
        context.fill();

        context.beginPath();
        pathGenerator(data.land);
        context.fillStyle = landGradient;
        context.fill();

        context.beginPath();
        pathGenerator(data.borders);
        context.strokeStyle = '#ffffff';
        context.lineWidth = 0.5;
        context.stroke();

        // Draw visible locations
        locations.forEach(location => {
            const [longitude] = location.coordinates;
            const [rotLongitude] = rotation.current;

            // Adjust visibility check to ensure locations reappear correctly on both left and right rotations
            const adjustedLongitude = (longitude - rotLongitude + 180 + 360) % 360 - 180;
            const isVisible = Math.abs(adjustedLongitude) <= 90;

            if (!isVisible) return;

            const projected = projection(location.coordinates);
            if (projected && projected[0] >= 0 && projected[0] <= 800 && projected[1] >= 0 && projected[1] <= 500) {
                const [x, y] = projected;
                const lineHeight = 60;
                const centerX = 400;
                const centerY = 250;
                const angle = Math.atan2(y - centerY, x - centerX);
                const endX = x + lineHeight * Math.cos(angle);
                const endY = y + lineHeight * Math.sin(angle);

                const lineGradient = context.createLinearGradient(x, y, endX, endY);
                lineGradient.addColorStop(0, 'rgba(72, 201, 176, 1)');
                lineGradient.addColorStop(1, 'rgba(72, 201, 176, 0)');

                context.beginPath();
                context.moveTo(x, y);
                context.lineTo(endX, endY);
                context.strokeStyle = lineGradient;
                context.lineWidth = 4;
                context.shadowColor = 'rgba(72, 201, 176, 0.8)';
                context.shadowBlur = 20;
                context.stroke();

                context.shadowBlur = 0;
                context.beginPath();
                context.arc(x, y, 5, 0, 2 * Math.PI);
                context.fillStyle = 'rgba(72, 201, 176, 1)';
                context.fill();
            }
        });
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        if (!canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const dpr = window.devicePixelRatio || 1;
        const offsetX = (event.clientX - rect.left) * dpr;
        const offsetY = (event.clientY - rect.top) * dpr;

        if (isDragging) {
            const deltaX = event.clientX - lastMousePosition.current.x;
            lastMousePosition.current = { x: event.clientX, y: event.clientY };

            rotation.current = [
                rotation.current[0] + deltaX * 0.3,
                rotation.current[1]
            ];
            requestAnimationFrame(render);
        } else {
            const projection = geoOrthographic()
                .scale(250)
                .translate([400, 250])
                .rotate(rotation.current);

            const hovered = locations.find(location => {
                const projected = projection(location.coordinates);
                if (projected) {
                    const [x, y] = projected;
                    const withinCanvas = x >= 0 && x <= 800 && y >= 0 && y <= 500;
                    const closeToMouse = Math.abs(x - offsetX) < 10 && Math.abs(y - offsetY) < 10;
                    return withinCanvas && closeToMouse;
                }
                return false;
            });

            if (hovered) {
                setHoveredLocation(hovered);
                setTooltipPos({ x: event.pageX + 15, y: event.pageY + 15 });
            } else {
                setHoveredLocation(null);
                setTooltipPos(null);
            }
        }
    };

    const handleMouseDown = (event: React.MouseEvent) => {
        setIsDragging(true);
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        render();
    }, [data, hoveredLocation]);

    return (
        <>
            <Heading>Internationally</Heading>
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, ease: 'easeOut' }}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}
            >
                <canvas
                    ref={canvasRef}
                    width={600}
                    height={500}
                    style={{
                        zIndex: 4,
                        cursor: isDragging ? 'grabbing' : 'grab',
                        width: '600px',
                        height: '450px'
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                />
                {hoveredLocation && tooltipPos && (
                    <div
                        style={{
                            position: 'absolute',
                            left: tooltipPos.x,
                            top: tooltipPos.y,
                            padding: '8px',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: '#fff',
                            borderRadius: '4px',
                            pointerEvents: 'none',
                            zIndex: 1000,
                        }}
                    >
                        {hoveredLocation.name} <br />
                        Projects: {hoveredLocation.projects}
                    </div>
                )}
            </motion.div>
        </>
    );
};

export default WorldGlobe;
